import React from "react";
import axios from "axios";
import styled from "@emotion/styled";
import {
  MantineProvider,
  Container,
  SimpleGrid,
  Title,
  Flex,
  Text,
  Modal,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";

// import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import TechnicianCard from "./SearchTechs/TechnicianCard";
import StagehandRequestForm from "./SearchTechs/StagehandRequestForm";
import RequestSubmittedConfirmation from "./SearchTechs/RequestSubmittedConfirmation";

const REQUEST_FOR_STAGEHANDS_WORKFLOW_URL =
  "https://api.retool.com/v1/workflows/f717f248-8529-4869-ad66-3c6383dce0e1/startTrigger?workflowApiKey=retool_wk_4edef9650dce4fef95a5719fe781357e";

function Gagan() {
  const [searchedTechs, setSearchedTechs] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  // const [isSuccessVisible, setIsSuccessVisible] = React.useState(false);
  const [opened, { open, close }] = useDisclosure(false);

  const handleRequestForStagehands = (formValues) => {
    setIsLoading(true);
    axios
      .post(
        REQUEST_FOR_STAGEHANDS_WORKFLOW_URL,
        {
          ...formValues,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        setIsLoading(false);
        renderRequestSubmittedSuccess();
      })
      .catch((error) => {
        setIsLoading(false);
        window.alert(error.message || "Error sending your request");
      });
  };

  const renderRequestSubmittedSuccess = () => {
    open();
    // setIsSuccessVisible(true);
    setTimeout(() => {
      // setIsSuccessVisible(false);
      close();
    }, 3000);
  };

  React.useEffect(() => {
    setIsLoading(true);
    axios
      .post(
        "https://api.retool.com/v1/workflows/3cbe172d-0468-4887-9c9f-a14f95dd1656/startTrigger?workflowApiKey=retool_wk_ecdd65e1c29f462bacee9d1a7160c06f"
      )
      .then((res) => {
        setIsLoading(false);
        console.log(res);
        setSearchedTechs(res.data.techs);
      })
      .catch(() => {
        setIsLoading(false);
        window.alert("Error fetching featured technicians");
      });
  }, []);

  return (
    <MantineProvider withGlobalStyles withNormalizeCSS>
      <Modal
        opened={opened}
        onClose={close}
        centered
        withCloseButton
        overlayProps={{
          backgroundopacity: 0.55,
          blur: 3,
        }}
      >
        <RequestSubmittedConfirmation />
      </Modal>
      <Main>
        <Container size="lg" pt={20}>
          <Title size="h1" c="#3e4066" ta="center">
            I need Stage Hands!
          </Title>
          <StagehandRequestForm
            onSubmit={(values) => {
              handleRequestForStagehands(values);
              // renderRequestSubmittedSuccess();
            }}
          />
          <Flex>
            <Text size={20} weight={600} color="#3e4066">
              Featured Stage Hands
            </Text>
          </Flex>
          <SimpleGrid
            mt={16}
            cols={4}
            verticalSpacing="xl"
            breakpoints={[
              { maxWidth: "76em", cols: 2, spacing: "md" },
              { maxWidth: "62em", cols: 2, spacing: "sm" },
              { maxWidth: "48em", cols: 1, spacing: "sm" },
            ]}
          >
            {isLoading &&
              [...Array(12).keys()].map((_, index) => (
                <TechnicianCard key={index} isLoading={true} />
              ))}
            {!isLoading &&
              searchedTechs.map((data) => {
                if (!data) return null;
                return <TechnicianCard key={data.id} tech={data} />;
              })}
          </SimpleGrid>
        </Container>
      </Main>
    </MantineProvider>
  );
}

const Main = styled.main`
  background: transparent;
  /* background: black; */
  color: white;
  /* width: 100vw;
  min-height: 40vw; */
  text-decoration: none;
`;

export default Gagan;
