export function isValidPhoneNumber(input) {
  const format = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im;
  return format.test(input);
}

export const SERVICE_REGIONS = [
  "Los Angeles Metro",
  "SF Bay Area",
  "NYC Metro",
  "Miami/South Florida",
  "Phoenix",
  "Las Vegas",
  "San Diego",
  "Orlando/Central Florida",
  "Nashville",
  "Chicagoland",
  "Washington DC",
  "Atlanta",
  "DFW Metroplex",
  "New Orleans",
  "Boston/New England",
  "Other",
];

export const GIG_ROLES = [
  "A2",
  "V1",
  "A1",
  "V2",
  "Graphics Op (GFX)",
  "Lighting Director (LD)",
  "L2",
  "Video Wall Tech (Lead)",
  "Video Wall Assist",
  "General AV",
  "Stage Hand",
  "Livestream Op",
  "Camera Operator",
  "Camera Operator (Robotic)",
  "Show Caller",
  "Editor",
  "Technical Director",
  "Technical Director (Switch Operator)",
  "Technical Producer",
  "Post-Production Supervisor",
  "Producer",
  "Production Assistant (PA)",
  "Director",
  "Frequency Coordinator",
  "Truck Driver",
  "Gaffer",
  "Grip",
  "LED Wall Engineer",
  "Live Stream Op",
  "Warehouse Operations Manager",
  "Tricaster Operator",
  "Drone Pilot",
  "Production Coordinator",
  "Stage Manager",
  "Teleprompter Operator",
  "Head Camera",
  "Event Marketer",
  "Consultant",
  "Desktop Support Technician",
  "Vmix Operator",
  "Zoom Operator",
  "Photographer",
  "Cinematographer",
  "Storyboard Artist",
  "1st Assistant Camera",
  "2nd Assistant Camera",
  "Focus Puller",
  "Gimbal Operator",
  "Audio Engineer",
];
