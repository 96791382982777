import React from "react";
import styled from "@emotion/styled";
import {
  Container,
  Grid,
  Select,
  Button,
  em,
  getBreakpointValue,
  NumberInput,
  TextInput,
} from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { useForm, zodResolver } from "@mantine/form";
import { IconSearch } from "@tabler/icons-react";
import { z } from "zod";

import { isValidPhoneNumber } from "./utilities";
// Create schema for request form
const formSchema = z.object({
  roleQuantity: z.number().nonnegative().min(1, { message: "Required" }),
  serviceRegion: z.string().min(1, { message: "Required" }).max(255),
  dates: z.array(z.coerce.date()).nonempty(),
  name: z.string().min(1, { message: "Required" }).max(255),
  email: z.string().email({ message: "Invalid email address" }),
  phoneNumber: z
    .string()
    .min(1, { message: "Required" })
    .max(255)
    .refine(isValidPhoneNumber, "Must be a valid phone number"),
});

const StagehandRequestForm = ({ onSubmit }) => {
  const urlParams = new URLSearchParams(window.location.search);
  const serviceRegionParamValue = urlParams.get("loc");

  const sericeRegionDropdown = [
    { value: "la", label: "Los Angeles Metro" },
    { value: "sf", label: "SF Bay Area" },
    { value: "nyc", label: "NYC Metro" },
    { value: "mia", label: "Miami/South Florida" },
    { value: "phx", label: "Phoenix" },
    { value: "las", label: "Las Vegas" },
    { value: "san", label: "San Diego" },
    { value: "orl", label: "Orlando/Central Florida" },
    { value: "nash", label: "Nashville" },
    { value: "chi", label: "Chicagoland" },
    { value: "dc", label: "Washington DC" },
    { value: "atl", label: "Atlanta" },
    { value: "dfw", label: "DFW Metroplex" },
    { value: "nola", label: "New Orleans" },
    { value: "bos", label: "Boston/New England" },
  ];

  const form = useForm({
    initialValues: {
      roleQuantity: 1,
      serviceRegion: serviceRegionParamValue || "",
      dates: [],
      name: "",
      phoneNumber: "",
      email: "",
    },
    validate: zodResolver(formSchema),
    validateInputOnChange: true,
  });

  function handleSubmit() {
    if (!form.isValid()) return;
    onSubmit(form.values);
    form.reset();
  }

  return (
    <SearchContainer size="lg" p={15} pt={25} my={40}>
      <form
        onSubmit={(e) => {
          form.onSubmit(() => handleSubmit());
          e.preventDefault();
        }}
      >
        <Grid
          grow
          sx={(theme) => ({
            [`@media (max-width: ${em(
              getBreakpointValue(theme.breakpoints.md) - 1
            )})`]: {
              flexDirection: "column",
            },
          })}
        >
          <Grid.Col span={3}>
            <Select
              label="Role"
              size="md"
              radius="0.625rem"
              placeholder="Search Role..."
              styles={{
                input: {
                  border: "1px solid #dbe2eb",
                },
              }}
              data={["Stage Hand"]}
              value={"Stage Hand"}
              icon={<IconSearch width="1.2rem" />}
              disabled={true}
            />
          </Grid.Col>
          <Grid.Col span={3}>
            <NumberInput
              label="How many"
              placeholder="Select number of stage hands"
              min={1}
              size="md"
              radius="0.625rem"
              {...form.getInputProps("roleQuantity")}
              styles={{
                input: {
                  border: "1px solid #dbe2eb",
                },
              }}
            />
          </Grid.Col>
          <Grid.Col span={3}>
            <Select
              label="Where"
              placeholder="Select service region"
              size="md"
              radius="0.625rem"
              searchable
              data={sericeRegionDropdown}
              {...form.getInputProps("serviceRegion")}
              styles={{
                input: {
                  border: "1px solid #dbe2eb",
                },
              }}
            />
          </Grid.Col>
          <Grid.Col span={3}>
            <DatePickerInput
              maw={400}
              mx="auto"
              size="md"
              radius="0.625rem"
              type="multiple"
              label="When"
              placeholder="Select dates"
              valueFormat="MMM DD YYYY"
              minDate={new Date()}
              {...form.getInputProps("dates")}
              styles={{
                input: {
                  border: "1px solid #dbe2eb",
                },
              }}
            />
          </Grid.Col>
          <Grid.Col span={3}>
            <TextInput
              label="Your name"
              radius="0.625rem"
              size="md"
              {...form.getInputProps("name")}
              styles={{
                input: {
                  border: "1px solid #dbe2eb",
                },
              }}
            />
          </Grid.Col>
          <Grid.Col span={3}>
            <TextInput
              label="Email"
              type="email"
              radius="0.625rem"
              size="md"
              {...form.getInputProps("email")}
              styles={{
                input: {
                  border: "1px solid #dbe2eb",
                },
              }}
            />
          </Grid.Col>
          <Grid.Col span={3}>
            <TextInput
              label="Phone"
              size="md"
              radius="0.625rem"
              {...form.getInputProps("phoneNumber")}
              styles={{
                input: {
                  border: "1px solid #dbe2eb",
                },
              }}
            />
          </Grid.Col>
          <Grid.Col span={3}>
            <Button
              size="md"
              w="100%"
              bg="#00f85d"
              c="#3e4066"
              radius="0.625rem"
              mt="1.5rem"
              disabled={!form.isValid()}
              onClick={handleSubmit}
              styles={(theme) => ({
                root: {
                  ":hover": {
                    backgroundColor: theme.fn.lighten("#00f85d", 0.55),
                  },
                },
              })}
            >
              Request
            </Button>
          </Grid.Col>
        </Grid>
      </form>
    </SearchContainer>
  );
};

const SearchContainer = styled(Container)`
  outline-offset: 4px;
  background-color: #fff;
  border-radius: 1rem;
  outline: 2px solid rgba(255, 255, 255, 0.4);
  padding: 1.25rem;
`;

export default StagehandRequestForm;
