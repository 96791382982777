import React from "react";
import styled from "@emotion/styled";
import { Container, Title, Text } from "@mantine/core";

const RequestSubmittedConfirmation = ({ onSubmit }) => {
  return (
    <CustomContainer>
      <Title size="h1" c="#3e4066" ta="center">
        Request Submitted!
      </Title>
      <Text fz={25.6} fw={400} mt={24} c="#3e4066" align="center">
        One of our team members will be in touch shortly.
      </Text>
    </CustomContainer>
  );
};

const CustomContainer = styled(Container)`
  outline-offset: 4px;
  background-color: #fff;
  border-radius: 1rem;
  outline: 2px solid rgba(255, 255, 255, 0.4);
  padding: 1.25rem;
`;

export default RequestSubmittedConfirmation;
