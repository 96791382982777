import atlanta from "../BackgroundLocationImages/atlanta-min.jpeg";
import boston from "../BackgroundLocationImages/boston-min.jpeg";
import chicago from "../BackgroundLocationImages/chicago-min.jpeg";
import dc from "../BackgroundLocationImages/dc-min.jpeg";
import dfw from "../BackgroundLocationImages/dfw-min.jpeg";
import lasVegas from "../BackgroundLocationImages/las_vegas-min.jpeg";
import losAngeles from "../BackgroundLocationImages/los_angeles-min.jpeg";
import miami from "../BackgroundLocationImages/miami-min.jpeg";
import nashville from "../BackgroundLocationImages/nashville-min.jpeg";
import newOrleans from "../BackgroundLocationImages/new_orleans-min.jpeg";
import nyc from "../BackgroundLocationImages/nyc-min.jpeg";
import orlando from "../BackgroundLocationImages/orlando-min.jpeg";
import other from "../BackgroundLocationImages/other-min.jpeg";
import phoenix from "../BackgroundLocationImages/phoenix-min.jpeg";
import sanDiego from "../BackgroundLocationImages/san_diego-min.jpeg";
import sf from "../BackgroundLocationImages/sf-min.jpeg";

const RenderBackgroundImage = (serviceLocation) => {
  // default image
  let image =
    "https://uploads-ssl.webflow.com/6495b5f56661ebc168b8411d/64be9bc04ad2ac254329798a_profile-cover.png";
  switch (serviceLocation) {
    case "Other":
      return other;
    case "Los Angeles Metro":
      return losAngeles;
    case "SF Bay Area":
      return sf;
    case "Boston/New England":
      return boston;
    case "NYC Metro":
    case "New York":
      return nyc;
    case "Las Vegas":
      return lasVegas;
    case "San Diego":
      return sanDiego;
    case "Nashville":
      return nashville;
    case "Chicagoland":
    case "Chicago":
      return chicago;
    case "Orlando/Central Florida":
      return orlando;
    case "Miami/South Florida":
      return miami;
    case "Washington DC":
      return dc;
    case "Atlanta":
      return atlanta;
    case "DFW Metroplex":
      return dfw;
    case "New Orleans":
      return newOrleans;
    case "Phoenix":
      return phoenix;
    default:
      return image;
  }
};

export default RenderBackgroundImage;
